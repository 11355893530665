import React from "react";
import { NavLink } from "react-router-dom";
import ButtonCloseCanvas from "../divers/ButtonCloseCanvas";

export default function MenuWeb() {
  return (
    <div className="flex-column d-flex">
      <NavLink
        className="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdownWeb"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Côté Conception
      </NavLink>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdownWeb">
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Conception de site web"}
            href={"/conception-de-site-web-professionnel/"}
            class={"dropdown-item fw-bold"}
          />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Maquette"}
            href={"/conception-de-site-web-professionnel/maquette-de-site-web/"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Développement"}
            href={
              "/conception-de-site-web-professionnel/developpement-de-site-web/"
            }
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Contenu"}
            href={"/conception-de-site-web-professionnel/contenu-de-site-web/"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Responsive | Mobile"}
            href={"/conception-de-site-web-professionnel/site-web-responsive/"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Hébergement sécurisé"}
            href={
              "/conception-de-site-web-professionnel/hebergement-de-site-web/"
            }
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Maintenance"}
            href={
              "/conception-de-site-web-professionnel/maintenance-de-site-web/"
            }
            class={"dropdown-item fst-italic"}
          />
        </li>
      </ul>
    </div>
  );
}
