import React from "react";
import { BiSolidContact } from "react-icons/bi";
import { NavLink } from "react-router-dom";

export default function CollapseContact() {
  return (
    <div id="canvas-contact">
      <button
        className="btn submit-btn"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        aria-label="contact"
      >
        <BiSolidContact />
        <p className="fs-4">Contact</p>
      </button>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
      >
        <div className="offcanvas-header position-absolute">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body d-flex flex-column justify-content-center">
          <div className="title-canvas mb-4">Contactez-moi...</div>
          <div className="coordonnees mb-5">
            <div>
              <a href="tel:+0682030266">06 82 03 02 66</a>
            </div>
            <div>
              <a href="mailto:contact@frwebdesign.net">
                contact@frwebdesign.net
              </a>
            </div>
          </div>
          <div>
            <a
              className="dropdown-item text-center fst-italic"
              aria-current="page"
              target="_blank"
              rel="noreferrer noopener"
              href="https://cal.com/frantz-fr-webdesign/premier-contact"
            >
              <em>Prenez RDV en ligne...</em>
            </a>
            <hr className="dropdown-divider w-25" />
            <NavLink
              className="dropdown-item text-center fst-italic"
              aria-current="page"
              target="_blank"
              rel="noreferrer noopener"
              to="/contactez-moi/"
            >
              <em>Via le formulaire...</em>
            </NavLink>
            {/* <hr className="dropdown-divider w-25" /> */}
            {/* <NavLink
              className="dropdown-item text-center fst-italic"
              aria-current="page"
              target="_blank"
              rel="noreferrer noopener"
              to="/demandez-votre-devis/"
            >
              Un devis sur-mesure ?
            </NavLink> */}
          </div>
        </div>
      </div>
    </div>
  );
}
