import React from "react";
import { Helmet } from "react-helmet";

export default function MetaHome() {
  return (
    <Helmet>
      <title>
        Consultant Web & SEO | Expert en visibilité digitale | FR Webdesign
      </title>
      <meta
        name="description"
        content="Boostez votre présence en ligne avec un consultant web & SEO passionné. Création de site web professionnel, référencement naturel et accompagnement sur-mesure pour développer votre clientèle."
      />
      <link rel="canonical" href="https://frwebdesign.net/" />
      <meta
        property="og:title"
        content="Consultant Web & SEO | Expert en visibilité digitale | FR Webdesign"
      />
      <meta
        property="og:description"
        content="Boostez votre présence en ligne avec un consultant web & SEO passionné. Création de site web professionnel, référencement naturel et accompagnement sur-mesure pour développer votre clientèle."
      />
      <meta property="og:type" content="siteweb" />
      <meta property="og:site_name" content="FR Webdesign" />
      <meta property="og:url" content="https://frwebdesign.net/" />
      <meta
        property="og:image"
        content="https://frwebdesign.net//images-site-web/meta/home.jpg"
      />
      <script type="application/ld+json">{`
      [
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "image": "https://frwebdesign.net//LOGO-FR-WEBDESIGN.svg",
          "@id":"https://frwebdesign.net/",
          "url":"https://frwebdesign.net/",
          "name": "Consultant Web & SEO | Expert en visibilité digitale | FR Webdesign",
          "description": "Boostez votre présence en ligne avec un consultant web & SEO passionné. Création de site web professionnel, référencement naturel et accompagnement sur-mesure pour développer votre clientèle.",
          "potentialAction": {
          "@type": "SearchAction",
          "target": "https://frwebdesign.net//search?q={search_term_string}",
          "query-input": "required name=search_term_string"
          }
        },
        {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "Consultant Web & SEO | Expert en visibilité digitale | FR Webdesign",
          "image": "https://frwebdesign.net//LOGO-FR-WEBDESIGN.svg",
          "telephone": "+33682030266",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "103, rue Victor Hugo",
            "addressLocality": "Leers",
            "postalCode": "59115",
            "addressCountry": "FR"
          },
          "geo": {
          "@type": "GeoCoordinates",
          "latitude": "50.68336553937294",
          "longitude": "3.2519920398914324"
          },
          "url": "https://frwebdesign.net/",
          "openingHours": "Mo-Fr 09:00-18:00",
          "priceRange": "€€"
        },
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Accueil",
              "item": "https://frwebdesign.net/"
            }
          ]
        }
      ]
    `}</script>
    </Helmet>
  );
}
