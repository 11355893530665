import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";

// Router singleton created
const router = createBrowserRouter([
  { path: "/", Component: Home },
  // --------------- Presentation ---------------
  {
    path: "/presentation/studio-web/",
    async lazy() {
      let { Studio } = await import("./pages/presentation/Studio");
      return { Component: Studio };
    },
  },
  {
    path: "/presentation/methode/",
    async lazy() {
      let { Methode } = await import("./pages/presentation/Methode");
      return { Component: Methode };
    },
  },
  {
    path: "/presentation/webdesigner-et-developpeur/",
    async lazy() {
      let { Webdesigner } = await import("./pages/presentation/Webdesigner");
      return { Component: Webdesigner };
    },
  },
  {
    path: "/presentation/mission/",
    async lazy() {
      let { Mission } = await import("./pages/presentation/Mission");
      return { Component: Mission };
    },
  },
  // --------------- Conception ---------------
  {
    path: "/conception-de-site-web-professionnel/",
    async lazy() {
      let { ConceptionSiteWeb } = await import(
        "./pages/conception/ConceptionSiteWeb"
      );
      return { Component: ConceptionSiteWeb };
    },
  },
  {
    path: "/conception-de-site-web-professionnel/maquette-de-site-web/",
    async lazy() {
      let { Maquette } = await import("./pages/conception/Maquette");
      return { Component: Maquette };
    },
  },
  {
    path: "/conception-de-site-web-professionnel/developpement-de-site-web/",
    async lazy() {
      let { Developpement } = await import("./pages/conception/Developpement");
      return { Component: Developpement };
    },
  },
  {
    path: "/conception-de-site-web-professionnel/contenu-de-site-web/",
    async lazy() {
      let { Contenu } = await import("./pages/conception/Contenu");
      return { Component: Contenu };
    },
  },
  {
    path: "/conception-de-site-web-professionnel/site-web-responsive/",
    async lazy() {
      let { Responsive } = await import("./pages/conception/Responsive");
      return { Component: Responsive };
    },
  },
  {
    path: "/conception-de-site-web-professionnel/hebergement-de-site-web/",
    async lazy() {
      let { Hebergement } = await import("./pages/conception/Hebergement");
      return { Component: Hebergement };
    },
  },
  {
    path: "/conception-de-site-web-professionnel/maintenance-de-site-web/",
    async lazy() {
      let { Maintenance } = await import("./pages/conception/Maintenance");
      return { Component: Maintenance };
    },
  },
  // --------------- Référencement ---------------
  {
    path: "/referencement-pour-site-web-professionnel/",
    async lazy() {
      let { ReferencementSiteWeb } = await import(
        "./pages/referencement/ReferencementSiteWeb"
      );
      return { Component: ReferencementSiteWeb };
    },
  },
  {
    path: "/referencement-pour-site-web-professionnel/referencement-naturel-seo/",
    async lazy() {
      let { Referencement } = await import(
        "./pages/referencement/Referencement"
      );
      return { Component: Referencement };
    },
  },
  {
    path: "/referencement-pour-site-web-professionnel/referencement-local/",
    async lazy() {
      let { Local } = await import("./pages/referencement/Local");
      return { Component: Local };
    },
  },
  {
    path: "/referencement-pour-site-web-professionnel/campagne-publicitaire-sea/",
    async lazy() {
      let { Campagne } = await import("./pages/referencement/Campagne");
      return { Component: Campagne };
    },
  },
  {
    path: "/referencement-pour-site-web-professionnel/tunnel-de-vente-et-landing-page/",
    async lazy() {
      let { Tunnel } = await import("./pages/referencement/Tunnel");
      return { Component: Tunnel };
    },
  },
  // --------------- Offres ---------------
  {
    path: "/offres/services-web-seo/",
    async lazy() {
      let { OffresGlobales } = await import("./pages/offres/OffresGlobales");
      return { Component: OffresGlobales };
    },
  },
  {
    path: "/offres/services-de-conception-de-site-web/",
    async lazy() {
      let { OffresConception } = await import(
        "./pages/offres/OffresConception"
      );
      return { Component: OffresConception };
    },
  },
  {
    path: "/offres/services-de-referencement-de-site-web/",
    async lazy() {
      let { OffresReferencement } = await import(
        "./pages/offres/OffresReferencement"
      );
      return { Component: OffresReferencement };
    },
  },
  {
    path: "/offres/services-de-tunnel-de-vente-et-landing-page/",
    async lazy() {
      let { OffresTunnel } = await import("./pages/offres/OffresTunnel");
      return { Component: OffresTunnel };
    },
  },
  {
    path: "/offres/services-de-maintenance-de-site-web/",
    async lazy() {
      let { OffresMaintenance } = await import(
        "./pages/offres/OffresMaintenance"
      );
      return { Component: OffresMaintenance };
    },
  },
  {
    path: "/offres/assistance-web-personnalise/",
    async lazy() {
      let { OffresAssistance } = await import(
        "./pages/offres/OffresAssistance"
      );
      return { Component: OffresAssistance };
    },
  },

  // --------------- Portolio ---------------
  {
    path: "/projets-de-site-web-professionnel/",
    async lazy() {
      let { Portfolio } = await import("./pages/Portfolio");
      return { Component: Portfolio };
    },
  },
  // --------------- Avis clients ---------------
  {
    path: "/avis-clients/",
    async lazy() {
      let { AvisClients } = await import("./pages/AvisClients");
      return { Component: AvisClients };
    },
  },
  // --------------- FAQ ---------------
  {
    path: "/faq/",
    async lazy() {
      let { Faq } = await import("./pages/Faq");
      return { Component: Faq };
    },
  },
  // --------------- Ressources ---------------
  {
    path: "/ressources-pour-site-web/",
    async lazy() {
      let { RessourcesSite } = await import("./pages/RessourcesSite");
      return { Component: RessourcesSite };
    },
  },
  // --------------- Contact ---------------
  {
    path: "/contactez-moi/",
    async lazy() {
      let { Contact } = await import("./pages/Contact");
      return { Component: Contact };
    },
  },
  // {
  //   path: "/demandez-votre-devis/",
  //   async lazy() {
  //     let { Devis } = await import("./pages/Devis");
  //     return { Component: Devis };
  //   },
  // },

  // --------------- Administratif ---------------
  {
    path: "/administratif/mentions-legales/",
    async lazy() {
      let { MentionsLegales } = await import(
        "./pages/administratif/MentionsLegales"
      );
      return { Component: MentionsLegales };
    },
  },
  {
    path: "/administratif/cgv/",
    async lazy() {
      let { Cgv } = await import("./pages/administratif/Cgv");
      return { Component: Cgv };
    },
  },
  {
    path: "/administratif/confidentialite/",
    async lazy() {
      let { Confidentialite } = await import(
        "./pages/administratif/Confidentialite"
      );
      return { Component: Confidentialite };
    },
  },
  {
    path: "/administratif/plan-du-site/",
    async lazy() {
      let { PlanSite } = await import("./pages/administratif/PlanSite");
      return { Component: PlanSite };
    },
  },

  {
    path: "*",
    async lazy() {
      let { NotFound } = await import("./pages/NotFound");
      return { Component: NotFound };
    },
  },
  // --------------- LOCAL - VILLENEUVE D'ASCQ ---------------
  {
    path: "/landing-page/conception-de-site-web-villeneuve-d-ascq/",
    async lazy() {
      let { VilleneuveAscqConception } = await import(
        "./pages/local/VilleneuveAscqConception"
      );
      return { Component: VilleneuveAscqConception };
    },
  },
  {
    path: "/landing-page/agence-seo-villeneuve-d-ascq/",
    async lazy() {
      let { VilleneuveAscqReferencement } = await import(
        "./pages/local/VilleneuveAscqReferencement"
      );
      return { Component: VilleneuveAscqReferencement };
    },
  },
  {
    path: "/landing-page/maintenance-de-site-web-villeneuve-d-ascq/",
    async lazy() {
      let { VilleneuveAscqMaintenance } = await import(
        "./pages/local/VilleneuveAscqMaintenance"
      );
      return { Component: VilleneuveAscqMaintenance };
    },
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
