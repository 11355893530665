import React, { Suspense, lazy, useEffect } from "react";
import BaseLayout from "../layout/BaseLayout";
import "../styles/pages/home.scss";
import { useLocation } from "react-router-dom";
import Intro from "../settings/home/Intro";
import ChiffresCle from "../settings/home/ChiffresCle";
import MetaHome from "../meta/MetaHome";

// const ProblemeSolution = lazy(() =>
//   import("../settings/home/ProblemeSolution")
// );
const Methode = lazy(() => import("../settings/home/Methode"));
const Presentation = lazy(() => import("../settings/home/Presentation"));
const Prestations = lazy(() => import("../settings/home/Prestations"));
const Portfolio = lazy(() => import("../settings/home/Portfolio"));
const Blog = lazy(() => import("../settings/home/Blog"));
const AvisClients = lazy(() => import("../settings/home/AvisClients"));
const Contact = lazy(() => import("../settings/home/Contact"));

export default function Home() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaHome />
      <BaseLayout>
        <div className="home">
          <Intro />
          <div id="scroll-to-id"></div>

          <Suspense fallback={"<div>Loading...</div>"}>
            <Methode />
            {/* <ProblemeSolution /> */}
            <Presentation />
            <Prestations />
            <ChiffresCle />
            <Portfolio />
            <Blog />
            <AvisClients />
            <Contact />
          </Suspense>
        </div>
      </BaseLayout>
    </>
  );
}
