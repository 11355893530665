import React from "react";
import { NavLink } from "react-router-dom";
import ButtonCloseCanvas from "../divers/ButtonCloseCanvas";

export default function MenuOffres() {
  return (
    <div className="flex-column d-flex">
      <NavLink
        className="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdownOffres"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Mes solutions digitales
      </NavLink>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdownOffres">
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Mes services web & SEO"}
            href={"/offres/services-web-seo/"}
            class={"dropdown-item fw-bold"}
          />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Conception de site web"}
            href={"/offres/services-de-conception-de-site-web/"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Référencement & visibilité"}
            href={"/offres/services-de-referencement-de-site-web/"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Maintenance technique"}
            href={"/offres/services-de-maintenance-de-site-web/"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Accompagnement"}
            href={"/offres/assistance-web-personnalise/"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        {/* <li>
          <hr className="dropdown-divider" />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Tunnel de vente & Landing Page"}
            href={"/offres/services-de-tunnel-de-vente-et-landing-page/"}
            class={"dropdown-item fst-italic"}
          />
        </li> */}
      </ul>
    </div>
  );
}
