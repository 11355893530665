import React from "react";

export default function ScrollToBottom() {
  const ScrollToBottom = () => {
    const scrollToId = document.getElementById("scroll-to-id");
    scrollToId.scrollIntoView({ behavior: "smooth" });
  };

  return (
    // <motion.div
    //   initial={{ opacity: 0, scale: 0.25 }}
    //   animate={{ opacity: 1, scale: 1 }}
    //   transition={{ duration: 1.5, delay: 1.5 }}
    // >
    <div className="scroll-down" onClick={ScrollToBottom}>
      <span className="fa fa-angle-down" aria-hidden="true"></span>
    </div>
    // </motion.div>
  );
}
