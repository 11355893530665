import React from "react";
import { NavLink } from "react-router-dom";

export default function SiteInfos() {
  return (
    <div className="border-top" id="site-info">
      <div className="site-info text-center mt-4 pb-4">
        &copy; 2025 |{" "}
        <NavLink className="lien_footer" to="/">
          By FR Webdesign | Votre partenaire web & SEO.{" "}
        </NavLink>
        Tous droits réservés. <br />
        <NavLink className="lien_footer" to="/administratif/mentions-legales/">
          Mentions Légales
        </NavLink>
        <span> | </span>
        <NavLink className="lien_footer" to="/administratif/cgv/">
          Conditions Générales de Ventes
        </NavLink>
        <span> | </span>
        <NavLink className="lien_footer" to="/administratif/confidentialite/">
          Confidentialité
        </NavLink>
        <span> | </span>
        <NavLink className="lien_footer" to="/administratif/plan-du-site/">
          Plan de Site
        </NavLink>
      </div>
    </div>
  );
}
