import React from "react";
import ButtonLiens from "../../components/divers/ButtonLiens";
import { Col, Row } from "react-bootstrap";
import DynamicTitle from "../../components/effects/DynamicTitle";
import ScrollToBottom from "../../components/effects/ScrollToBottom";

const Intro = () => {
  return (
    <Row className="intro position-relative" id="header_bg_home">
      <Col className="d-flex flex-column align-items-center justify-content-center">
        <h1>
          <div className="question">Développez votre visibilité</div>
          <div className="reponse fst-italic">
            avec un consultant web & SEO engagé !
          </div>
        </h1>
        <DynamicTitle />
        <div className="slogan">
          Votre succès digital commence ici, dans votre région !
        </div>
        <ButtonLiens
          buttonLink="/offres/services-web-seo/"
          buttonText="Développons votre visibilité"
          buttonClass="mt-5"
        />
        <ScrollToBottom />
      </Col>
    </Row>
  );
};

export default Intro;
